.App {
  text-align: center;
}

.App-logo {
  height: 60vmin;
  pointer-events: none;
}

@media only screen and (max-width: 600px) {
  .App-logo {
    height: 75vmin;
  }
}

.Img-container {
  position: relative;
  bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .Img-container {
    position: relative;
    bottom: 45px;
  }
}

.Footer-container {
  position: relative; 
  top: 25px;
  margin-left: 25px;
  margin-right: 25px;
}

@media only screen and (max-width: 600px) {
  .Footer-text {
    font-size: 22px;
  }
}

.App-header {
  background-color: #0a384f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.main-container {
  background-color: #0a384f;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  overflow: hidden;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
